<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col><el-breadcrumb><el-breadcrumb-item class="pointer" :to="{ path: '/operator/aprl' }">导航</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col>
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip class="item" effect="dark" content="保存基本信息" placement="bottom">
                          <el-button type="primary" size="small" @click="submitForm('systemConfig')">保存</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="返回列表" placement="bottom">
                          <el-button @click="goIndex()" size="small">返回</el-button>
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form :model="systemConfig" ref="systemConfig" label-width="150px" class="systemConfig" size="mini"
                  :rules="rules">
                  <el-row>
                    <!-- <el-col :sm="24" :md="24" :lg="12"   >
                                                <el-form-item label="id" prop="id">
                                                    <el-input v-model="systemConfig.id" :disabled="true"></el-input>
                                                </el-form-item>
                                            </el-col> -->
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="联系人" prop="contacts">
                        <el-input v-model="systemConfig.contacts" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="联系人电话" prop="contactsPhone">
                        <el-input v-model="systemConfig.contactsPhone" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="微信号" prop="email">
                        <el-input v-model="systemConfig.email" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="头像" prop="operatorLogo">
                        <el-image style="width: 100px; height: 100px" :src="systemConfig.operatorLogo"
                          :preview-src-list="operatorLogo">
                        </el-image>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="运营商名称" prop="operatorName">
                        <el-input v-model="systemConfig.operatorName" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="运营商地址" prop="operatorAddress">
                        <el-input v-model="systemConfig.operatorAddress" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="运营商登录账号" prop="systemConfig">
                        <el-input v-model="systemConfig.operatorAccount" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="身份证正面" prop="idCardFrontSide">
                        <el-image style="width: 100px; height: 100px" :src="systemConfig.idCardFrontSide"
                          :preview-src-list="idCardFrontSide">
                        </el-image>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="身份证反面" prop="idCardSecondSide">
                        <el-image style="width: 100px; height: 100px" :src="systemConfig.idCardSecondSide"
                          :preview-src-list="idCardSecondSide">
                        </el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="审批状态">
                        <el-select v-model="systemConfig.examineStatus">
                          <!-- <el-option label="未审核" value="1"></el-option> -->
                          <el-option label="已审核" :value="1"></el-option>
                          <el-option label="未审核" :value="0"></el-option>
                          <el-option label="审核拒绝" :value="2"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12" v-show="systemConfig.examineStatus == 2">
                      <el-form-item label="审批拒绝原因" prop="examineInfo">
                        <el-input v-model="systemConfig.examineInfo"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12" v-show="systemConfig.examineStatus == 1">
                      <el-form-item label="收益比" prop="systemConfig">
                        <el-input v-model="systemConfig.incomeRatio"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      page: 1,
      limit: 10,
      seeThis: 0,
      loading: true,
      total: 0,
      id: "",
      idCardFrontSide: [],
      idCardSecondSide: [],
      operatorLogo: [],
      systemConfig: {
        contacts: "",
        contactsPhone: "",
        createDt: "",
        email: "",
        examineInfo: "",
        examineStatus: "",
        id: "",
        incomeRatio: "",
        operatorAccount: "",
        operatorAddress: "",
        operatorName: "",
        operatorPassword: "",
        idCardSecondSide: "",
        idCardFrontSide: ""
      }
    };
  },
  methods: {
    judgeStatus(examineStatus) {
      if (examineStatus === 0) {
        return "未审批";
      } else if (examineStatus === 1) {
        return "已审批";
      } else {
        return "审批拒绝";
      }
    },
    goIndex() {
      this.$router.go(-1);
    },
    submitForm(systemConfig) {
      if (this.systemConfig.incomeRatio.length < 1) {
        this.$notify.error({
          title: "消息",
          message: "收益比不能为空",
          showClose: true
        });
        return;
      }
      this.$http
        .post("/userOperator/save", this.systemConfig)
        .then(res => {
          //   console.log("ss"+that.id)
          if (res.data.code == 200) {
            this.$router.push("/operator/Aprl");
            this.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true
            });
          }
        });
    },

    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/userOperator/list", {
            page: 1,
            limit: 10,
            condition: {
              id: that.id
            }
          })
          .then(function (response) {
            if (response.status == 200) {
              that.total = response.data.data.count;
              that.systemConfig = response.data.data.data[0];
              that.idCardFrontSide.push(that.systemConfig.idCardFrontSide);
              that.idCardSecondSide.push(that.systemConfig.idCardSecondSide);
              that.operatorLogo.push(that.systemConfig.operatorLogo);
              // that.systemConfig.examineStatus=''
              that.systemConfig.examineStatus = parseInt(
                that.systemConfig.examineStatus
              );
              // console.log(response.data.data.data[0])
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      //   var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      //   if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
      //     this.seeThis = 0;
      //   }
      //   if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
      //     this.seeThis = 1;
      //   }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    }
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  }
};
</script>

<style lang="less" scope>
.el-image {
  width: 200px !important;
  height: auto !important;
}

.h50 {
  height: 50px;
}

.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }

  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;

      li {
        height: 50px;
        line-height: 50px;

        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }

      li.active,
      li:hover {
        background-color: #fff;
      }
    }

    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;

      .p1 {
        margin-bottom: 20px;
      }

      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;

          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }

          .el-input {
            width: 247px;
          }

          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }

      .header-input {
        font-size: 13px;
      }

      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }

        .el-table {
          margin-top: 10px;
        }
      }
    }
  }

  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }

  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }

  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
